import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  min-height: 100vh;
`

export default function MainContainer({ children }){

  return <Container>{children}</Container>

}
