
import '../styles/globals.css'
import Head from 'next/head'
import { DefaultSeo } from 'next-seo';

import { AuthProvider } from 'contexts/AuthContext'
import { GlobalProvider } from 'contexts/GlobalContext'
import MainContainer from 'components/MainContainer'

import { ThemeProvider } from 'styled-components'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { mainTheme, muiTheme } from 'lib/theme'


function MyApp({ Component, pageProps }) {
  return <>
    <Head>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no" />

      <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
      <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
      <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
      <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
      <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
      <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
      <link rel="icon" type="image/png" sizes="192x192"  href="/android-icon-192x192.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/manifest.json" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />

      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,600;1,200;1,400;1,600&display=swap" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap" rel="stylesheet" />

      <script src="/sw.js?cache=0"></script>

    </Head>
    <DefaultSeo
      title={'ArcherReady'}
      description={'The Archery portal for personal growth, community nurturement, and having fun'}
      openGraph={{
        title: 'ArcherReady',
        type: 'website',
        site_name: 'ArcherReady',
        description: 'The Archery portal for personal growth, community nurturement, and having fun',
        images: [{
          url: `https://i.ibb.co/C7LhQh5/Archer-Ready.png`,
          width: 512,
          height: 512,
          alt: 'ArcherReady: Life as an archer',
        }]
      }}
    />

    <ThemeProvider theme={mainTheme}>
      <MuiThemeProvider theme={muiTheme}>
        <AuthProvider>
          <GlobalProvider>
            <MainContainer>
              <Component {...pageProps} />
            </MainContainer>
          </GlobalProvider>
        </AuthProvider>
      </MuiThemeProvider>
    </ThemeProvider>

  </>
}

export default MyApp
