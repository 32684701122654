import { createMuiTheme } from '@material-ui/core/styles';

export const mainTheme = {

  breakpoints: {
    phone: 0,
    tablet: 768,
    screen: 1024,
    large: 1440,
  },

  headerHeight: '55px',
  menuWidth: '233px',

  primary: '#2ecc71',
  warning: '#ff9800',
  danger: '#e91e63',
  muted: '#aeaeae',
  logo: '#f50075',

  contentPadding: {
    normal: 16,
    tablet: 32,
    screen: 64
  },

  maxContentWidth: 1440,

  torgba: (str, alpha) => {


    if(!/^#([A-Fa-f0-9]{3}){1,2}$/.test(str))
      throw new Error('Bad hex')


    let c = str.substring(1).split('')
    if(c.length === 3) c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    c = '0x'+c.join('');
    return `rgba(${(c>>16)&255}, ${(c>>8)&255}, ${c&255}, ${alpha})`

  },

};


export const muiTheme = createMuiTheme({
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    primary: {
      main: mainTheme.primary,
      contrastText: '#ffffff',
    },
    muted: {
      light: '#eceff1',
      main: '#aeaeae',
      dark: '#90a4ae',
      contrastText: '#424242',
    }
  }
});
