import { useState, useEffect, createContext, useContext, useRef } from 'react'


import getConfig from 'next/config'

const defaultData = {

};

const GlobalContext = createContext({ ...defaultData });
const useGlobal = () => useContext(GlobalContext);

const useGlobalData = () => {

  return {}

}


const GlobalProvider = ({ children }) => {

  const data = useGlobalData();

  return (
    <GlobalContext.Provider value={data}>{children}</GlobalContext.Provider>
  );
};

export { GlobalProvider, useGlobal };
